import * as React from 'react';
import { StyledReviews } from './reviews.styles';
import Review from './review';
import { ReviewFragmentFragment, ReviewsQueryQuery, Maybe } from '../../gatsby-graphql';
import { useInterval } from '../helpers/timers';
import { useReviews } from '../hooks/reviews.hook';

export interface IReviewsProps {
    reviewIds?: (Maybe<number> | undefined)[];
    category?: Maybe<number>;
    sorted?: boolean;
    large?: boolean;
};

export const hasReviews = (data: ReviewsQueryQuery) => {
    return !!data.safetyFirst.reviews?.nodes && data.safetyFirst.reviews.nodes !== null && data.safetyFirst.reviews.nodes !== undefined;
}

export const getReviewsByIds = (ids: (Maybe<number> | undefined)[], reviews?: Maybe<ReviewFragmentFragment>[] | null) => {
    return reviews && reviews.filter((review: Maybe<ReviewFragmentFragment>) => {
        if (review && review.databaseId) {
          return ids.includes(review.databaseId);
        }
    });
}

export const getReviewsByCategory = (categoryId?: Maybe<number>, reviews?: Maybe<ReviewFragmentFragment>[] | null) => {
    return (reviews && categoryId && categoryId !== 0) ? reviews.filter((review: Maybe<ReviewFragmentFragment>) => {
        if (review && review.categories?.nodes) {
            return review.categories.nodes.filter((category) => {
                return category?.databaseId === categoryId;
          }).length > 0;
        }
    }) : reviews;
}

// Picks a random number between 1 and $max
// Uses $prevNumber to not repeat
export const getRandomNumber = (max: number, prevNumber?: number) => {
    let newRandom: number = max > 1 ? Math.floor(Math.random() * (max - 1)) + 1 : 1;

    if (max > 1 && prevNumber) {
        newRandom = getRandomNumber(max, prevNumber);
    }

    return newRandom;
}

export const Reviews: React.FC<IReviewsProps> = props => {
  const reviews = useReviews();
  const [reviewInView, setReviewInView] = React.useState<number>(1);
  const [reviewsInView, setReviewsInView] = React.useState(false);
  const reviewsElementRef = React.useRef<HTMLDivElement>(null);
  const [reviewsObserver, setReviewsObserver] = React.useState<IntersectionObserver | null>(null);
  const usedReviews = props.reviewIds ? getReviewsByIds(props.reviewIds, reviews?.nodes) : getReviewsByCategory(props.category, reviews?.nodes);
  const sortedReviews = (props.sorted && usedReviews && props.reviewIds) ? usedReviews.sort((first: Maybe<ReviewFragmentFragment>, second: Maybe<ReviewFragmentFragment>) => {
      if (props.reviewIds && !!first && !!second) {
      return props.reviewIds?.indexOf(first.databaseId) - props.reviewIds?.indexOf(second.databaseId);
      }

      return 0;
  }) : usedReviews;

  React.useEffect(() => {
    !props.sorted && updateReviewInOrder();

    return () => {
    };
  },
  []
  );

  React.useEffect(() => {

    if ( !!reviewsElementRef.current ) {

      if (!reviewsObserver) {
        const reviewsObserverOptions = {
            rootMargin: '-90px 0px -10% 0px',
            thresholds: [0]
          };
          setReviewsObserver(new IntersectionObserver(updateReviewsInView, reviewsObserverOptions));
      } else {
          reviewsObserver.observe(reviewsElementRef.current);
      }
    }

    return () => {
        !!reviewsObserver && !!reviewsElementRef.current && reviewsObserver.unobserve(reviewsElementRef.current);
    };
  },
  [reviewsObserver, reviewsElementRef]
  );

  const updateReviewsInView = React.useCallback(
      (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
  
          entries.forEach((entry: IntersectionObserverEntry) => {
              setReviewsInView(entry.isIntersecting);
          });
      },
      [setReviewsInView]
  );

  const updateReviewInOrder = React.useCallback(
    () => {
      if (props.sorted) {
          const newReviewInView = (sortedReviews && reviewInView < sortedReviews.length) ? reviewInView + 1 : 1;
          setReviewInView(newReviewInView);
      } else {
          const newReviewInView = sortedReviews ? getRandomNumber(sortedReviews.length) : 1;
          setReviewInView(newReviewInView);
      }
    },
    [reviewInView, setReviewInView]
  );

  // Only cycle reviews if more than one
  sortedReviews && sortedReviews?.length > 1 && useInterval(updateReviewInOrder, reviewsInView ? 7500 : null);

  return (
    <StyledReviews reviewInView={reviewInView} ref={reviewsElementRef}>
      {sortedReviews && sortedReviews.map((review, index) => {
        return <Review 
          review={review}
          reviews={sortedReviews}
          large={props.large}
          key={index}
        />
      })}
    </StyledReviews>
  );
};
