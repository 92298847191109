import * as React from 'react';
import { StyledParagraph } from './paragraph.styles';
import { graphql } from 'gatsby';
import { CMSContentPart } from '../../parts/cms-content';
import { InViewElement } from '../../parts/in-view-element';
import { ParagraphBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface IParagraphBlockProps extends ParagraphBlockFragmentFragment, BaseBlockProps {};

export const ParagraphBlock: React.FC<IParagraphBlockProps> = props => {
  const attributes = props.attributes;

  return (
      <StyledParagraph paragraphFontSize={attributes?.fontSize || undefined} paragraphAlign={attributes?.align || undefined} dropCap={attributes?.dropCap}>
          <InViewElement>
            <CMSContentPart content={attributes?.content || ''} />
          </InViewElement>
      </StyledParagraph>
  );
};

export const ParagraphBlockFragment = graphql`
    fragment ParagraphBlockFragment on WpCoreParagraphBlock {
      attributes {
          ... on WpCoreParagraphBlockAttributes {
              align
              content
              fontSize
              dropCap
          }
      }
    }
`;